import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [IconFieldModule, InputIconModule, FormsModule, CommonModule, InputTextModule],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @Input() loading = false;
  @Input() placeholder = 'Search';
  @Input() debounceTime = 0;
  @Input() hideIcon = false;
  @Input() inputClass = '';
  @Input() disabled = false;
  @Output() onSearch = new EventEmitter<string>();
  @Output() onClick = new EventEmitter();

  unsubscribe$ = new Subject();
  keyword = '';
  keywordChange$ = new Subject();

  ngOnInit() {
    this.keywordChange$
      .pipe(distinctUntilChanged(), debounceTime(this.debounceTime), takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.onSearch.emit(this.keyword);
        }
      });
  }

  onInput(e: Event) {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    this.keywordChange$.next(value);
  }

  onClearKeyword() {
    this.keyword = '';
    this.keywordChange$.next('');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
