<div class="relative" (click)="onClick.emit()">
  <p-iconField iconPosition="left">
    @if (!hideIcon) {
      @if (loading) {
        <p-inputIcon styleClass="pi pi-spinner pi-spin" />
      } @else {
        <p-inputIcon styleClass="pi pi-search" />
      }
    }
    <input
      type="text"
      pInputText
      [class]="inputClass"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [(ngModel)]="keyword"
      (input)="onInput($event)" />
  </p-iconField>

  @if (keyword) {
    <div class="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer" (click)="onClearKeyword()">
      <i class="icon-wl-close text-4"></i>
    </div>
  }
</div>
