import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusUser } from '@core/enum/user';
import { ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { GetUserParams, UserCreate, UserDetail } from '@core/models/interfaces/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = `${environment.API_URL}/crm/users`;

  constructor(private httpClient: HttpClient) {}

  getUsers(params?: GetUserParams) {
    return this.httpClient.get<ResponseListApi<UserDetail>>(this.baseUrl, { params: { ...params } });
  }

  createUser(body: UserCreate) {
    return this.httpClient.post<ResponseApi<UserDetail>>(this.baseUrl, body);
  }

  deleteUser(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/${id}`);
  }

  updateUser(id: string, body: UserCreate) {
    return this.httpClient.put(`${this.baseUrl}/${id}`, body);
  }

  getUserById(id: string) {
    return this.httpClient.get<ResponseApi<UserDetail>>(`${this.baseUrl}/${id}`);
  }

  changeStatusUserById(id: string, status: StatusUser) {
    let params = new HttpParams();
    if (status) {
      params = params.set('status', status);
    }
    return this.httpClient.put(`${this.baseUrl}/${id}/status`, null, { params });
  }
}
